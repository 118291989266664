@import './utils/browserify.scss';
@import './variables.scss';

@mixin blur($parameter) {
  @include browserify(filter, blur($parameter), 'chrome');

  @supports not ((-webkit-filter: none) or (filter: none)) {
    background: #{rgba(#000000, 0.5)};
  }
}

@mixin border-radius($parameters) {
  @include browserify(border-radius, $parameters, 'chrome' 'firefox');
}

@mixin transition($parameters...) {
  @include browserify(transition, $parameters, 'chrome' 'firefox' 'opera');
}

@mixin flex-direction($direction) {
  @include browserify(flex-direction, $direction, 'chrome' 'firefox' 'explorer');
}

@mixin blurBackdrop($parameter) {
  @include browserify(backdrop-filter, blur($parameter), 'chrome');

  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background: #{rgba($colorDarkBlackless, 0.5)};
  }
}

@mixin transform($parameters) {
  @include browserify(transform, $parameters, 'chrome' 'firefox' 'opera');
}

@mixin box-shadow($parameters...) {
  @include browserify(box-shadow, $parameters, 'chrome' 'firefox');
}

@mixin mediumScreen($mediumScreenWidth: 1024px) {
  @media screen and (max-width: $mediumScreenWidth) {
    @content;
  }
}

@mixin mobile($mobileScreenWidth: 768px) {
  @media screen and (max-width: $mobileScreenWidth) {
    @content;
  }
}
