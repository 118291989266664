$colorsDefaultMarkup: (
  light: rgba(137, 151, 166, 0.15),
  danger: rgba(255, 65, 58, 0.15),
  info: rgba(41, 215, 242, 0.15),
  success: rgba(147, 201, 10, 0.15),
  warning: rgba(236, 170, 0, 0.15),
);

$colorsDefaultMarkupTint: (
  light: #8997a6,
  danger: #ff413a,
  info: #29d7f2,
  success: #93c90a,
  warning: #ecaa00,
);

$colorDarkBlackless: #000000;
$colorDarkContrast: #1e222d;
$colorDefault: #007bff;
$colorDefaultOpacity: rgba(0, 123, 255, 0.1);
$colorLightContrast: #f7f8f9;
$colorLightWhiteless: #ffffff;
$colorDarkContrast: #1e222d;
$colorDarkBlackless: #10121a;
