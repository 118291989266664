@import '../../../theme/variables.scss';
@import '../../../theme/autoprefixer.scss';

.steps {
  align-items: center;
  display: flex;
  position: relative;
  width: 100%;
  margin-bottom: 16px;

  .icon {
    @include border-radius(50%);
    @include transition(border 0.3s ease);
    align-items: center;
    background-color: var(--vkit-color-contrast);
    border: 2px solid var(--vkit-color-contrast);
    display: flex;
    justify-content: center;
    position: relative;
    transition-delay: 0.3s;
    z-index: 2;
    padding: 4px;

    &.done {
      @include transition(border 0.3s ease);
      transition-delay: 0s;
      border: 2px solid var(--vkit-color-default);
    }

    &:hover {
      .text {
        top: calc(100% + 16px);
        opacity: 1;
      }
    }

    .text {
      @include transition(opacity 0.3s ease, top 0.3s ease);
      @include border-radius(5px);
      @include box-shadow(0 0 8px var(--vkit-color-blackless));
      background-color: var(--vkit-color-contrast);
      border: 1px solid var(--vkit-color-contrast-3);
      padding: 8px;
      position: absolute;
      top: 100%;
      opacity: 0;
      pointer-events: none;

      &::before,
      &::after {
        content: '';
        position: absolute;
        right: calc(50% - 10px);
        top: -11px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid var(--vkit-color-contrast-3);
      }

      &::after {
        border-bottom: 10px solid var(--vkit-color-contrast);
        padding-top: 1px;
      }
    }

    &:first-child {
      justify-content: left;

      .text {
        left: -1px;

        &::before,
        &::after {
          right: calc(100% - 24px);
        }
      }
    }

    &:last-child {
      justify-content: right;

      .text {
        right: -1px;

        &::before,
        &::after {
          right: 4px;
        }
      }
    }
  }

  .line {
    @include border-radius(5px);
    background-color: var(--vkit-color-contrast-3);
    height: 2px;
    width: 100%;
    display: flex;
    flex-grow: 1;

    &::before {
      @include transition(width 0.3s ease);
      @include border-radius(5px);
      background-color: var(--vkit-color-default);
      content: '';
      height: 2px;
      width: 0;
    }

    &.active {
      &::before {
        width: 100%;
      }
    }
  }
}
