.divider {
  border-width: thin 0 0;
  border-style: solid;
  border-color: var(--vkit-color-contrast-1);
  width: 100%;

  &.noBorder {
    border-color: transparent;
  }
}
