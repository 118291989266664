@import '../../../theme/main.scss';

.floatAction {
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    background: var(--vkit-color-default-8);
    padding: 5px;
    @include border-radius(4px);
  }
}
