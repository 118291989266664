@import '../../../theme/main.scss';

.list {
  [class*='list_head_'] {
    > a {
      padding: 10px 10px 10px 16px !important;
    }
  }

  &.blur {
    height: 100%;
    width: 100%;
    @include blur(5px);
  }
}
