@import '../../../theme/variables.scss';
@import '../../../theme/autoprefixer.scss';

.boxInfo {
  margin-bottom: 24px;

  .content {
    @include border-radius(6px);
    background-color: $colorLightWhiteless;

    [class*='list_list'] {
      [class*='list_body'], [class*='list_col'] {
        background-color: $colorLightWhiteless;
      }
    }

    .title {
      display: flex;
      justify-content: space-between;
      padding: 19px 19px 0 19px;

      @include mobile {
        .progressBar {
          margin-top: 16px;
        }
      }
    }

    .childrenContent {
      padding: 20px;
    }
  }
}

[data-theme='dark'] {
  .content {
    background-color: $colorDarkBlackless;

    [class*='list_list'] {
      [class*='list_body'], [class*='list_col'] {
        background-color: $colorDarkBlackless;
      }
    }
  }
}