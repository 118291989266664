@import '../../../theme/autoprefixer.scss';

.showHide {
  display: block;
  height: 0;
  position: relative;
  @include transition(opacity 0.3s ease);
  width: 100%;
  opacity: 0;

  > .contentShowHide {
    display: block;
    @include transition(transform 0.3s ease);
    width: 100%;
  }

  @mixin neutralPosition {
    &.visible {
      opacity: 1;

      > .contentShowHide {
        @include transform(none);
      }
    }
  }

  &.slideToLeft {
    > .contentShowHide {
      @include transform(translateX(-10%));
    }

    &:last-of-type {
      > .contentShowHide {
        @include transform(translateX(10%));
      }
    }

    @include neutralPosition;
  }

  &.slideToDown {
    > .contentShowHide {
      @include transform(translateY(-30px));
    }

    &:last-of-type {
      > .contentShowHide {
        @include transform(translateY(30px));
      }
    }

    @include neutralPosition;
  }
}
