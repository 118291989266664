.wrapperPage {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-width: 1366px;
  min-height: 100vh;
  padding: 16px 24px 0 24px;
  width: 100%;

  &.mobile {
    padding: 50px 16px 24px;
  }
}
