@import '../../../theme/autoprefixer.scss';

.wrapper {
  display: flex;

  &.default {
    width: 200px;
  }

  .progressBar {
    width: 100%;
    background: var(--vkit-color-contrast-1);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .progress {
      height: 100%;
      background: var(--vkit-color-default);
      @include transition(width 0.3s ease);
    }
  }

  .progressStatus {
    width: 50px;
  }
}
