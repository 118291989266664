.buttonGroup {
  display: flex;

  &:not(.solo) {
    > div {
      &:not(:first-child):not(:last-child) {
        [class*="button_button"] {
          border-radius: 0;
        }
      }

      &:first-child {
        [class*="button_button"] {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      &:last-child {
        [class*="button_button"] {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
}
