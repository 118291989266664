@import './autoprefixer.scss';

[class^=page_sidebar__] {
  display: none;
}

[class^=page_pageWrapper__] {
  min-width: 100vw;
}

body > iframe {
  display: none;
}
